import Image from 'next/image'
import React from 'react'
import router from 'next/router'

const RewardsBadge = (data) => {
  const queryString = JSON.stringify(data.data)
  const onExplorRewards = () => {
    router.push({
      pathname: '/rewards',
      query: {
        data: queryString
      }
    })
  }

  return (
      <div className='rewards-badge'>
          <Image
            src={data?.data?.image_url}
            alt="rewards box"
            width={80}
            height={80}
            onClick={onExplorRewards}
          />
      </div>
  )
}

export default RewardsBadge
