import React, { useContext, useState, useEffect } from 'react'
import { IonCard, useIonModal } from '@ionic/react'
import Context from '../context'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import StoreSearch from '../components/StoreSearch'

import { useAuthAPI } from '../hooks/api.hooks'
import SVG from './SVG'

dayjs.extend(customParseFormat)

const BopisTile: React.FC = () => {
  const { state } = useContext(Context)
  const [orders, setOrders] = useState([])
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [css, setCSS] = useState('')
  const BOPIS_TILE_CTA = 'bopis-tile-cta'
  const BOPIS_TILE_CTA_80 = 'bopis-tile-cta-80'
  const MODAL_FULLSCREEN = 'modal--fullscreen'
  const authAPICall = useAuthAPI()

  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(StoreSearch, {
    handleDismiss
  })

  useEffect(() => {
    const getOrders = async () => {
      if (state.user.appSessionToken) {
        setLoading(true)
        const data = await authAPICall('user', 'orders', 'GET', {}, {})
        if (data?.length) {
          const filtered = data.filter((order) =>
            [1, 3, 5, 6, 8, 9, 11, 12, 15, 27, 31, 26, 32].includes(order.OrderStatusID)
          )
          setOrders([...filtered])
          setLoading(false)
        } else {
          setOrders([])
          setLoading(false)
        }
      }
    }
    getOrders()
  }, [state.user])

  const render = () => {
    if (orders.length > 1) {
      if (css !== 'bopis-tile-store-multiple') {
        setCSS('bopis-tile-store-multiple')
      }
      return (
        <div className={`${BOPIS_TILE_CTA} ${loading ? BOPIS_TILE_CTA : BOPIS_TILE_CTA_80}`}>
          <button className="bopis-cta bopis-cta-multiple" onClick={() => router.push('/orders')}>
            <p className="ref-effect"> Multiple Orders Placed </p>
            <h1 className="ref-effect"> VIEW YOUR ORDERS</h1>
          </button>
        </div>
      )
    } else if (orders.length === 1) {
      switch (orders[0].OrderStatusID) {
        case 1:
        case 3:
        case 5:
        case 6:
        case 8:
        case 9:
        case 11:
        case 12:
        case 15:
        case 27:
        case 31:
          if (css !== 'bopis-tile-store-preparing') {
            setCSS('bopis-tile-store-preparing')
          }
          return (
            <div className={`${BOPIS_TILE_CTA} ${loading ? 'BOPIS_TILE_CTA' : BOPIS_TILE_CTA_80}`}>
              <button
                className="bopis-cta bopis-cta-preparing"
                onClick={() => router.push(`/order/${orders[0].OrderGUID}`)}
              >
                <span>Order Status</span>
                <label>ORDER RECEIVED - BEING PREPARED</label>
              </button>
            </div>
          )
        case 26:
        case 32:
          if (css !== 'bopis-tile-store-ready') {
            setCSS('bopis-tile-store-ready')
          }
          return (
            <div className={`${BOPIS_TILE_CTA} ${loading ? 'BOPIS_TILE_CTA' : BOPIS_TILE_CTA_80}`}>
              <button
                className="bopis-cta bopis-cta-ready"
                onClick={() => router.push(`/order/${orders[0].OrderGUID}`)}
              >
                <span>Order Status</span>
                <label>READY TO PICK UP</label>
              </button>
            </div>
          )
        default:
          if (css !== 'bopis-tile-store-no-store') {
            setCSS('bopis-tile-store-no-store')
          }
          return (
            <div className={`${BOPIS_TILE_CTA} ${loading ? 'BOPIS_TILE_CTA' : BOPIS_TILE_CTA_80}`}>
              <button className="bopis-cta bopis-cta-started">
                <span className="ref-effect"> Order Status </span>
                <label className="ref-effect"> NO PICKUP ORDER STARTED</label>
              </button>
            </div>
          )
      }
    }
  }
  if (!orders.length && orders.length > 0) {
    return (
      <IonCard className="bopis">
        <div
          className="bopis-tile-store bopis-tile-store-no-store home__store"
          style={{ width: `${loading ? '100%' : '50%'}` }}
        >
          <div className="ref-effect bopis_location_icon">
            <SVG name="bopis--location" label="bopis--location" size="md" />
          </div>
          <h3>
            <span id="store-title" className="ref-effect">
              Your store
            </span>
            {state.cart.store?.storenumber && (
              <span id="store-location" className="ref-effect">
                {state.cart.store?.address}, {state.cart.store?.city}, {state.cart.store?.state}
              </span>
            )}

            {state.cart.store?.storenumber && (
              <span id="store-time">
                Closes at {dayjs(
                state.cart.store[
                    `hours_${dayjs().format('ddd').toLowerCase()}`
                ].split(':')[1],
                'hhmm'
              ).format('h:mm A')}
              </span>
            )}
          </h3>
          <button
            aria-labelledby={'store-title store-location store-time'}
            className="change-store-icon ref-effect"
            onClick={() => present({ cssClass: MODAL_FULLSCREEN })}
          >
            <SVG name="change--store" label={`Change your store, ${state.cart.store?.address}, ${state.cart.store?.city}, ${state.cart.store?.state}`} size="base" />
          </button>
        </div>
        <div className={`${BOPIS_TILE_CTA} ${loading ? 'BOPIS_TILE_CTA' : BOPIS_TILE_CTA_80}`}>
          <button className="bopis-cta bopis-cta-started">
            <span className="ref-effect"> Order Status </span>
            <label className="ref-effect"> NO PICKUP ORDER STARTED</label>
          </button>
        </div>
      </IonCard>
    )
  } else if (orders.length > 0) {
    return (
      <IonCard className="bopis bopis__container">
        <div
          data-testid="bopis-tile-card"
          className={`bopis-tile-store ${css} home__store`}
          style={{ width: `${loading ? '100%' : '50%'}` }}
        >
          <div className="ref-effect bopis_location_icon">
            <SVG name="bopis--location" label="bopis--location" size="md" />
          </div>
          <h3>
            <span id="store-title" className="ref-effect">
              Your store
            </span>
            {state.cart.store?.storenumber && (
              <span id="store-location" className="ref-effect">
                {state.cart.store?.address}, {state.cart.store?.city}, {state.cart.store?.state}
              </span>
            )}
            {state.cart.store?.storenumber && (
              <span id="store-time">
                Closes at {dayjs(
                state.cart.store[
                    `hours_${dayjs().format('ddd').toLowerCase()}`
                ].split(':')[1],
                'hhmm'
              ).format('h:mm A')}
              </span>
            )}
          </h3>
          <button
            data-testid="change-store-icon"
            aria-labelledby="store-title store-location store-time"
            className="change-store-icon ref-effect"
            onClick={() => present({ cssClass: MODAL_FULLSCREEN })}
          >
            <SVG name="change--store" label={`Change your store, ${state.cart.store?.address}, ${state.cart.store?.city}, ${state.cart.store?.state}`} size="base" />
          </button>
        </div>
        {render()}
      </IonCard>
    )
  } else {
    return <div></div>
  }
}

export default BopisTile
