const LoyaltyRewardCircle = ({ reward }) => {
  const rewardCircleContent = () => {
    if (reward.type === 'money') {
      return (
        <>
          <span className='reward-container__content-dollar'>$</span>
          <p className='reward-container__content-amount'>{reward.amount}</p>
        </>
      )
    } else if (reward.type === 'discount') {
      return (
        <>
          <div className='reward-container__content-discount'>
            <div className='reward-container__content-discount-percent'>
              <h1>{reward.amount}</h1>
              <span>%</span>
            </div>
            <p className='reward-container__content-discount-off'>OFF</p>
          </div>
        </>
      )
    }
  }

  return (
        <div className='reward-container'>
            <div className='reward-container__content'>
            { rewardCircleContent() }
            </div>
        </div>
  )
}

export default LoyaltyRewardCircle
