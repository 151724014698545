import Image from 'next/image'
import Link from 'next/link'
import RewardsBoxImage from '../public/images/ps-box.jpg'
import Context from '../context'
import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import SVG from './SVG'
import LoyaltyCircleMeter from './LoyaltyCircleMeter'
import LoyaltyRewardCircle from './LoyaltyRewardCircle'
import RewardsBadge from './RewardsBadge'
const { publicRuntimeConfig } = getConfig()

const RewardsBanner = () => {
  const { state } = useContext(Context)
  const router = useRouter()
  const [userType, setUserType] = useState('')
  // State to determine whether to show the badges
  const [isVisible, setIsVisible] = useState(false)
  const [rewardDetails, setRewardDetails] = useState({})
  const [loyalty, setLoyalty] = useState(null)
  const [isBadge, setIsBadge] = useState(true)

  useEffect(() => {
    setUserType(state.user.authType ? 'auth' : 'guest')
    setLoyalty(state.loyalty)
  }, [])

  useEffect(() => {
    if (loyalty) {
      rewardDetail(loyalty)
      exploreRewardBtn(loyalty)
    }
  }, [loyalty])

  const calculateTimeDifference = (expiringUTCTime, currentUTCTime) => {
    const differenceInMilliseconds = new Date(expiringUTCTime).valueOf() - new Date(currentUTCTime).valueOf()
    return differenceInMilliseconds
  }

  const rewardDetail = (loyalty) => {
    const currentTime = new Date()
    let rewardDetailData = {}
    if (loyalty?.badge && loyalty?.points) {
      if (currentTime.getTime() >= new Date(loyalty?.badge?.expires).getTime()) {
        rewardDetailData = {
          balance: loyalty.points?.balance,
          description: loyalty.points?.description,
          title: loyalty.points?.title,
          isPoints: true
        }
        setIsBadge(false)
        setRewardDetails(rewardDetailData)
      } else {
        rewardDetailData = {
          title: loyalty.badge?.title,
          description: loyalty.badge?.description,
          image_url: loyalty.badge?.image_url,
          isBadge: true
        }
        setRewardDetails(rewardDetailData)

        const expiringUTCTime = loyalty?.badge?.expires
        const currentUTCTime = currentTime.toISOString().slice(0, -1)
        const differenceInMS = calculateTimeDifference(expiringUTCTime, currentUTCTime)

        const timer = setTimeout(() => {
          rewardDetailData = {
            balance: loyalty.points?.balance,
            description: loyalty.points?.description,
            title: loyalty.points?.title,
            isPoints: true
          }
          setRewardDetails(rewardDetailData)
        }, (differenceInMS))
        return () => clearTimeout(timer)
      }
    } else {
      if (loyalty?.reward) {
        rewardDetailData = {
          title: loyalty.reward?.title,
          amount: loyalty.reward?.amount,
          description: loyalty.reward?.description,
          isExpiringSoon: loyalty.reward?.isExpiringSoon,
          type: loyalty.reward?.type
        }
      } else if (loyalty?.points) {
        rewardDetailData = {
          balance: loyalty.points?.balance,
          description: loyalty.points?.description,
          title: loyalty.points?.title,
          isPoints: true
        }
      } else if (loyalty?.badge) {
        rewardDetailData = {
          title: loyalty.badge?.title,
          description: loyalty.badge?.description,
          image_url: loyalty.badge?.image_url,
          isBadge: true
        }
      }
      setRewardDetails(rewardDetailData)
    }
  }

  const rewardSubHeadingClassMap = {
    badge: 'rewards-banner__section-subheading-badge',
    points: 'rewards-banner__section-subheading-points',
    default: 'rewards-banner__section-subheading-default'
  }

  const rewardSubheadingClass = (loyalty) => {
    if (loyalty?.badge || loyalty?.reward?.type === 'discount') {
      return rewardSubHeadingClassMap.badge
    } else if (loyalty?.points?.balance > 999) {
      return rewardSubHeadingClassMap.points
    } else if (!loyalty?.reward?.isExpiringSoon) {
      return rewardSubHeadingClassMap.badge
    } else {
      return rewardSubHeadingClassMap.default
    }
  }

  const rewardContainerStyle = (loyalty) => {
    if (!loyalty?.badge) {
      return 'rewards-banner__section__details rewards-banner__section__badge'
    } else if (loyalty?.points?.balance > 999) {
      return 'rewards-banner__section__details rewards-banner__section__point-balance'
    } else {
      return 'rewards-banner__section__details'
    }
  }

  const rewardContainerClass = () => {
    if (userType === 'auth') {
      return 'rewards-banner__section-container'
    } else {
      return 'rewards-banner__section__sign-in'
    }
  }

  const exploreRewardBtn = (loyalty) => {
    if (loyalty?.points?.balance > 999 && loyalty?.badge) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  const badgeHeadingStyle = (loyalty) => {
    if (loyalty?.points?.balance > 999) {
      return { color: '#87189D', lineHeight: '26px' }
    }
  }

  const rewardPointsCircle = (loyalty) => {
    if (loyalty?.reward) {
      return <LoyaltyRewardCircle reward={rewardDetails}/>
    } else if (isBadge && loyalty?.badge) {
      return < RewardsBadge data={loyalty?.badge} />
    } else if (loyalty?.points) {
      return <LoyaltyCircleMeter points={ loyalty?.points?.balance}/>
    }
  }

  const onExploreButton = () => {
    router.push({
      pathname: '/rewards'
    })
  }

  const rewardsComponent = () => {
    if (userType === 'auth') {
      return (
          <>
            { rewardPointsCircle(loyalty) }
            <div className={rewardContainerStyle(loyalty)}>
                <h1 style={badgeHeadingStyle(loyalty)}>{(rewardDetails as { title: string }).title}</h1>
                <p className={rewardSubheadingClass(loyalty)}>{(rewardDetails as { description: string }).description}</p>
                { isVisible && <button onClick={ onExploreButton }>EXPLORE REWARDS</button> }
            </div>
          </>
      )
    } else if (userType === 'guest') {
      return (
                <><h1>Register to be instantly enrolled in our rewards program, pOpshelf peeps!</h1><div className='rewards-banner__section__button'>
                    <button className='rewards-banner__section__button-register' onClick={() => router.push('/registration?redirectedURL=')}>REGISTER</button>
                    <button className='rewards-banner__section__button-signin' onClick={() => router.push('/login?redirectedURL=')}>SIGN IN</button>
                </div></>
      )
    } else {
      return (<></>)
    }
  }

  if (publicRuntimeConfig.LOYALTY_ENABLED === 'false') {
    return (
        <Link href="/rewards">
            <a className="rewards-banner-old">
                <section className="rewards-banner-old__section">
                    <div className="rewards-banner-old__first-section">
                        <div className="rewards-banner-old__box rewards-banner-old__box--mobile">
                            <Image
                                src={RewardsBoxImage}
                                alt="rewards box"
                                width={90}
                                height={65}
                            />
                        </div>
                        <h1 className="rewards-banner-old__title rewards-banner-old__title--desktop">welcome to pOpshelf®</h1>
                    </div>
                    <div className="rewards-banner-old__second-section">
                        <h1 className="rewards-banner-old__title rewards-banner-old__title--mobile">welcome to pOpshelf®</h1>
                        <div className="rewards-banner-old__subtitle banner-subtitle" >
                            your reward box awaits!
                            <div className="rewards-banner-old__subtitle-arrow">
                                <SVG name="arrow-right" label="Arrow-right" size="sm" />
                            </div>
                        </div>
                        <div className="rewards-banner-old__box rewards-banner-old__box--desktop">
                            <Image
                                src={RewardsBoxImage}
                                alt="rewards box"
                                width={90}
                                height={60.7}
                            />
                        </div>
                    </div>
                </section>
            </a>
        </Link>
    )
  }

  return (
    <section className="rewards-banner__section">
       <div className={rewardContainerClass()}>
        { publicRuntimeConfig.LOYALTY_ENABLED === 'true' && rewardsComponent() }
        </div>
    </section>
  )
}

export default RewardsBanner
