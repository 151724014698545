import React, { useEffect, useContext, useState } from 'react'
import getConfig from 'next/config'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Pagination, FreeMode, Navigation, SwiperOptions } from 'swiper'
import { useAuthAPI } from '../hooks/api.hooks'
import Context from '../context'
import MiniProductCard from './MiniProductCard'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/keyboard'

const { publicRuntimeConfig } = getConfig()

const RecentlyViewed = () => {
  const { state, dispatch } = useContext(Context)
  const [sellable, setSellable] = useState([])
  const authAPICall = useAuthAPI()
  const swiperConfig: SwiperOptions = {
    modules: [FreeMode, Pagination, Navigation, Keyboard],
    freeMode: true,
    pagination: { clickable: true },
    navigation: {
      nextEl: '.swiper-button-next-recently-viewed',
      prevEl: '.swiper-button-prev-recently-viewed'
    },
    spaceBetween: 16,
    keyboard: true,
    watchOverflow: true
  }

  const getProducts = () => {
    const skuList = state?.recentlyViewed?.map((product) => product.sku)?.join(',')
    authAPICall(
      'productinfo',
      'products',
      'GET',
      {
        popkey: publicRuntimeConfig.POP_KEY,
        store: state.cart.store?.storenumber || publicRuntimeConfig.DEFAULT_STORE,
        skus: skuList
      },
      {},
      { deviceid: state.deviceId || '' }
    ).then((data) => {
      if (data.length) {
        setSellable(data)
      }
      const recentProductsCopy = state.recentlyViewed
      for (const productCard of data) {
        const index = recentProductsCopy.findIndex((x) => +x.sku === +productCard.sku)
        if (index !== -1) {
          recentProductsCopy[index].image_urls = productCard.image_urls
          recentProductsCopy[index].in_store_only = `${productCard.in_store_only}`
          recentProductsCopy[index].product_name = productCard.product_name
          recentProductsCopy[index].inventory = productCard.qtyOnHand
          recentProductsCopy[index].in_stock = productCard.qtyOnHand > 0
          recentProductsCopy[index].price = productCard.price
          recentProductsCopy[index].discount_price = productCard.discount_price
        }
      }

      dispatch({ type: 'SET_RECENTLY_VIEWED', payload: recentProductsCopy })
    })
  }

  useEffect(() => {
    if (state?.recentlyViewed && state?.recentlyViewed.length) {
      getProducts()
    }
  }, [state.cart?.store])

  return (
    <div className="recently_viewed">
      {!!state?.recentlyViewed?.length && !!sellable.length && (
        <>
          <h2 className="recently_viewed_title">Recently Viewed</h2>
          <section className="swiper__section">
            <div className="swiper-button-prev-recently-viewed swiper-navbutton swiper-navbutton-prev" />
            <div className="swiper-button-next-recently-viewed swiper-navbutton swiper-navbutton-next" />
            <Swiper {...swiperConfig} slidesPerView={'auto'}>
              {state.recentlyViewed
                .slice(0, 9)
                .filter((filteredProduct) => {
                  const index = sellable.findIndex((x) => +x.sku === +filteredProduct.sku)
                  return index !== -1
                })
                .map((product, i) => (
                  <SwiperSlide key={`${product.slug}-${i}`}>
                    <MiniProductCard data={{ ...product, image_urls: product.image, product_name: product.title }} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </section>
        </>
      )}
    </div>
  )
}

export default RecentlyViewed
