import { useEffect, useState } from 'react'

const LoyaltyCircleMeter = ({ points }) => {
  const [point, setPoint] = useState(0)

  useEffect(() => {
    setPoint(points)
  }, [points])

  return (
    <div className='reward-pointer-container' style={{ background: `conic-gradient(from 0deg at 50% 50%, #87189D 0deg, #D269DF ${(17 / 100) * point}deg, #87189D ${(36 / 100) * point}deg, #E5D4ED ${(38 / 100) * point}deg, #E5D4ED 360deg)` }}>
        <div className='reward-pointer-container__inner-circle'>
            <span>{point}</span>
            <p>points</p>
        </div>
    </div>
  )
}

export default LoyaltyCircleMeter
